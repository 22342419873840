<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            name="search"
            type="date"
            label="교육년도"
            v-model="searchParam.year"
          ></c-datepicker>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            codeGroupCd="EDU_KIND_FST_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdLarge"
            label="교육종류1"
            v-model="searchParam.educationKindCdLarge"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            codeGroupCd="EDU_KIND_SEC_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdSmall"
            label="교육종류2"
            v-model="searchParam.educationKindCdSmall"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <!-- 교육구분 -->
          <c-select
            codeGroupCd="EDU_CLASS_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="educationTypeCd"
            label="교육구분"
            v-model="searchParam.educationTypeCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="연간 교육 계획 목록"
      tableId="table"
      :columns="grid.columns"
      :data="grid.data"
      selection="multiple"
      :checkClickFlag="false"
      :isExcelDown="false"
      rowKey="eduKey"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="연간교육계획결재" icon="assignment" @btnClicked="apprRequest" />
          <c-btn label="연간교육 추가" icon="add" @btnClicked="onItemClick" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'annual-inspection',
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'plantName' },
          { index: 1, colName: 'educationCourseName' },
        ],
        columns: [
           {
            name: "plantName",
            field: "plantName",
            label: "공사현장",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          // {
          //   name: "educationName",
          //   field: "educationName",
          //   label: "교육명",
          //   align: "left",
          //   style: 'width:250px',
          //   sortable: true,
          // },
          {
            name: "educationCourseName",
            field: "educationCourseName",
            label: "교육과정",
            align: "left",
            style: 'width:200px',
            sortable: true,
            type: "link",
          },
          {
            name: "educationKindCdLargeName",
            field: "educationKindCdLargeName",
            label: "교육종류1",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationKindCdSmallName",
            field: "educationKindCdSmallName",
            label: "교육종류2",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationTypeNames",
            field: "educationTypeNames",
            label: "교육구분",
            style: 'width:150px',
            align: "center",
            sortable: true,
          },
          {
            label: '연간교육일정',
            align: 'center',
            sortable: false,
            child: [
              {
                name: 'mon01',
                field: 'mon01',
                label: '1월',
                align: 'center',
                style: 'width:50px', 
                sortable: false,
                type: "link",
              },
              {
                name: 'mon02',
                field: 'mon02',
                label: '2월',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon03',
                field: 'mon03',
                label: '3월',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon04',
                field: 'mon04',
                label: '4월',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon05',
                field: 'mon05',
                label: '5월',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon06',
                field: 'mon06',
                label: '6월',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon07',
                field: 'mon07',
                label: '7월',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon08',
                field: 'mon08',
                label: '8월',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon09',
                field: 'mon09',
                label: '9월',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon10',
                field: 'mon10',
                label: '10월',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon11',
                field: 'mon11',
                label: '11월',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon12',
                field: 'mon12',
                label: '12월',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
            ]
          },
          {
            name: "relatedLawsName",
            field: "relatedLawsName",
            label: "관련법규",
            style: 'width:350px',
            align: "left",
            sortable: true,
          },
          {
            name: "departmentDeptNames",
            field: "departmentDeptNames",
            label: "주관부서",
            style: 'width:200px',
            align: "left",
            sortable: true,
          },
        ],
        data: [],
        
      },
      searchParam: {
        plantCd: null,
        year: '',
        educationKindCdSmall: null,
        educationKindCdLarge: null,
        educationTypeCd: null,
        legalEduYn: null,
      },
      editable: true,
      listUrl: '',
      completeUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.listUrl = selectConfig.sop.edu.annual.list.url;
      this.completeUrl = transactionConfig.sop.edu.annual.completes.url;
      // url setting
      // list setting
      this.searchParam.year = this.$comm.getThisYear();
      this.getList();
    },
    getList() {
      if (!this.searchParam.year) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: 
          // item.equipmentNm + 
          '교육년도를 선택하세요.', // 점검년도를 선택하세요
          type: 'warning', // success / info / warning / error
        });
        return;
      }
     // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;

        this.$_.forEach(this.grid.data, _item => {
          _item.eduKey = uuidv4();
        })
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.annualPopup(row, col, true);
    },
    onItemClick() {
      this.annualPopup(null, null, false);
    },
    apprRequest() {
      let selectData = this.$refs['table'].selected;
      
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '결재하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.completeUrl;
            this.$http.type = 'PUT';
            this.$http.param = selectData;
            this.$http.request((_result) => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
            window.getApp.$emit('APP_REQUEST_SUCCESS');

          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    annualPopup(result, col, flag) {
      let selectData = this.$refs['table'].selected;

      let title = '';
      let param = null;

      if (!col) {
        this.popupOptions.target = () =>
          import(`${"./annualEducationPlanDetail.vue"}`);
          param = {
            selectData: selectData ? selectData: [],
            eduEducationId: '',
            eduCourseId: '',
          };
        this.popupOptions.isFull = false;
          title = '연간교육계획 상세';
      } else if (col.name === 'educationCourseName') {
        this.popupOptions.target = () =>
          import(`${"./cc/educationCurriculumDetail.vue"}`);
          param = {
            eduCourseId: result.eduCourseId ? result.eduCourseId : '',
          };
        this.popupOptions.isFull = true;
          title = '교육과정 마스터 상세';
      } else {
        this.popupOptions.target = () =>
          import(`${"./annualEducationPlanDetail.vue"}`);
          param = {
            eduEducationId: result.eduEducationId ? result.eduEducationId : '',
            eduCourseId: result.eduCourseId ? result.eduCourseId : '',
            educationMonth: flag ? col.name.substring(3) : '',
            educationYear: flag ? result.educationYear : '',
            plantCd: flag ? result.plantCd : '',
          };
        this.popupOptions.isFull = false;
        title = '연간교육계획 상세';
      }
      this.popupOptions.title = title;
      this.popupOptions.param = param;
      this.popupOptions.width = '95%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.$refs['table'].selected = []
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (type === 'COMPLETE' || type === 'SAVE') {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
      }
      this.getList();
    },
  }
};
</script>